.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.bpMain {
    --column-gap: 3rem;
    --row-gap: 0.2rem;

    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-row-gap: var(--row-gap);
    grid-column-gap: var(--column-gap);
    grid-auto-flow: column;

    margin: 0.5rem;
    font-size: 1rem;
}

.bpColumn {
    grid-column: 1;
    display: flex;
    flex-direction: column;
    gap: var(--row-gap);
}
.bpColumn.bpc2 {
    grid-column: 2;
}
.bpColumn.bpc3 {
    grid-column: 3;
}
.bpColumn.bpc4 {
    grid-column: 4;
}

@media (max-width: 1250px) {
    .bpMain {
        grid-template-columns: repeat(2,1fr);
    }
    .bpColumn.bpc2 {
        grid-column: 1;
    }
    .bpColumn.bpc3 {
        grid-column: 2;
    }
    .bpColumn.bpc4 {
        grid-column: 2;
    }
}

@media (max-width: 625px) {
    .bpMain {
        grid-template-columns: repeat(1,1fr);
    }
    .bpColumn.bpc2 {
        grid-column: 1;
    }
    .bpColumn.bpc3 {
        grid-column: 1;
    }
    .bpColumn.bpc4 {
        grid-column: 1;
    }
}

.bpCategory {
    display: contents;
}

.bpCategory .planer-theme-grouping:last-of-type .planer-line:last-child {
    /*color: red;*/
    margin-bottom: 1rem;
}

.bpGroup {
    display: contents;
}

.planer-line {
    grid-column: var(--cat-column);
}

.planer-line p{
    margin: 0.5rem;
}

.planer-line-addition{
    position:absolute;
    right: 2rem;
    color: #444;
}

.planer-category-title{
    color: #fff;
    font-weight: bold;
    font-size: 1.2em;
    background-color: var(--cat-color);
}

.planer-group-title{
    font-weight: bold;
    font-size: 1.2em;
}

.planer-theme-grouping{
    display: contents;
}

.planer-entry{
    position: relative;
    cursor: pointer;
    background-color: #ccc;
}

.planer-entry:hover, .planer-entry:focus{
    /*outline: 1px solid #777;*/
    background-color: #bbb;
    background-color: color-mix(in srgb, #ccc 80%, var(--cat-color));
}

.planer-entry-disabled{
    cursor: default;
}

.planer-entry-subpoint{
    margin-left: 1rem;
}
.planer-entry-subpoint-hidden{
    display: none;
}

.planer-entry::after{
    content: '';
    background-color: var(--cat-color);
    display: block;
    width: 1.5rem;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}

.planer-entry-expandable::after {
    content: '\FF0B';
    color: #fff;
    font-weight: bolder;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
}
.planer-entry-expandable.expanded::after {
    content: '\FF0D';
}

.KursContainer{
    display: block;
    width: 100%;
    height: 100%;
}

.KursContainer .inner-container {
    border: 1px solid black;
    margin: 50px;
    height: calc(100vh - 100px);
    position: relative;
}

.KursContainer .inner-container .title {
    background-color: var(--course-color);
    cursor: pointer;
    margin: 0;
    color: white;
    padding: 10px;
    height: 1.3em;
}

.KursContainer .inner-container .download-container {
    position: absolute;
    bottom: 0;
    right: 0;
    width: min(400px, 100%);
    height: 60px;
    display: flex;
    flex-direction: column;
}

.KursContainer .inner-container .download-container a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--course-color);
    color: white;
    text-decoration: none;
    font-weight: bold;
}

.KursContainer .backButton {
    font-size: 1.5rem;
    cursor: pointer;
    position: absolute;
    right: 65px;
}

.KursContainer .kurs-content {
    padding: 30px 40px;
    height: calc(100% - 100px - 1.3em);
}

.evento-kurs-iframe {
    width: 100%;
    height: 100%;
    border: none;
}
